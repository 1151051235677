
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import 'src/css/mixins';

body { font-size: 18px; background: #FFFFFF; line-height: 125%; font-family: 'Lato', sans-serif; font-weight: 400; color: #4a4a4a }    
    h1, h2, h3, h4, h5, h6 {color: $pg-primary; display: block; clear: both; font-weight: 900; line-height: 130%; text-align: center; text-transform: uppercase;}
    h2 {font-size: 32px; margin: 0 0 30px 0;}
    h6 {font-size: 20px; margin: 0 0 20px 0;}
    
    .mouse-over{
        cursor: pointer;
    }
    
    p {padding: 0 0 20px 0;}

    a{
      color: $pg-primary;
      text-decoration: none;
      &:hover{
          text-decoration: none;
          color: $pg-primary-dark;
      }
    }

    .mob-only{
        display: none;
    }

    .hide-mob{
        display: block;
    }

    .lazyloaded {
        opacity: 1;
        transition: opacity .3s;
    }

    .btn-primary{
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
        &:hover, &:active{
            background-color: transparent;
            border-color: transparent;
    }
}

    .btn-background{
        background-color: $pg-primary;
        position: relative;
        width: 200px;
        padding-left: 1em;
        margin-left: 1em;
        &::after {
            content: "";
            position: absolute;
            right: 0px;
            bottom: 0px;
            border-top: 20px solid transparent;
            border-right: 30px solid #fff;
            overflow: hidden;
        }&:hover, &:active{
            background-color: $pg-primary-dark;
            cursor: pointer;
        }
    }



  .bg-light{
      background-color: #fff !important;
  }

  .bg-primary{
    background-color: $pg-primary !important;
    color: $pg-secondary !important;
    &h1, h2, h3, h4, h5, h6{
        color: $pg-secondary !important;
    }
  }
  
  .bg-secondary{
    background-color: $pg-secondary !important;
    color: $pg-primary !important;
    &h1, h2, h3, h4, h5, h6{
        color: $pg-secondary !important;
    }
  }

  .highlight{
      color: $pg-primary;
      border-color: $pg-primary !important;
  }
  
  .highlighted{
    background-color: $pg-primary;
    border-color: $pg-primary !important;
    color: #26272b;
  }
  
  .divide-bg{
      // background: linear-gradient(180deg, rgba(25,26,30,1) 0%, rgba(0,0,0,1) 50%, rgba(25,26,30,1) 100%);
      background-color: #FFF;
  }

.form-container{
    padding: 3rem 5rem;
}

.page--header-banner{
    object-fit: cover;
    height: 55vh;
    width: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
}

  .page--header{
    position: relative;
    top: -25px;
    left: 45px;
    overflow: hidden;
    max-width: 770px;
}
.style-bar{
    width: 55%;
    height: 40px;
    background-color: #FFF;
    position: relative;
    right: 0;
    bottom: 40px; 
        &::after {
        content: "";
        position: absolute;
        right: -65px;
        bottom: 0px;
        border-top: 40px solid transparent;
        border-left: 65px solid #fff;
        overflow: hidden;
    }
}

.content--img-right{
    content: '';
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    background-image: url(/assets/home_truck.jpeg);
    &::before {
        content: "";
        position: absolute;
        left: -0px;
        top: 0px;
        border-bottom: 75px solid transparent;
        border-left: 65px solid #fff;
        overflow: hidden;
    }
}

.content--img-left{
    content: '';
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    background-image: url(/assets/home_truck.jpeg);
    &::before {
        content: "";
        position: absolute;
        right: 0px;
        top: 0px;
        border-bottom: 75px solid transparent;
        border-right: 65px solid #fff;
        overflow: hidden;
    }
}

.content--inner-text{
    min-height: 300px;
    padding: 2rem;
    position: relative;
    a{
        color: $pg-primary;
        text-decoration: none;

    }&:hover{
        cursor: pointer;
        color: $pg-primary-dark;
    }
}

.content--header-bg{
    background: $pg-primary;
    color: white;
    width: 80%;
    position: relative;
    padding: 1.3rem;
    right: 0;
    left: 20%;
    &h1,h2,h3,h4,h5{
        color: white;
    }
    &::after {
        content: "";
        position: absolute;
        left: -0px;
        bottom: 0px;
        border-top: 2rem solid transparent;
        border-left: 65px solid #fff;
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) {
    .page--header{
        overflow: hidden;
        max-width: 530px;
    }

    .form-container{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 538px) {
    .page--header{
        overflow: hidden;
        max-width: 350px;
    }

    .mob-only{
        display: block;
    }

    .hide-mob{
        display: none;
    }

    .form-container{
        padding: 1rem 1rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    html, body {font-size: 14px; text-align: center;}
        
    h2 {font-size: 22px; margin: 0 0 20px 0;}
    h6 {font-size: 16px;}

    .page--header{
        overflow: hidden;
        max-width: 250px;
    }
}
    
